import { Directive, Input } from '@angular/core';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: 'img',
    host: {
        '(error)': 'updateUrl()',
        '[src]': 'src',
    },
})
export class FallbackImageDirective {
    @Input() src!: string;
    @Input() defaultUrl = './assets/shared/images/no_image.jpg';

    updateUrl(): void {
        this.src = this.defaultUrl;
    }
}
