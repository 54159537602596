import { CommonModule } from '@angular/common';
import {
    HTTP_INTERCEPTORS,
    provideHttpClient,
    withInterceptorsFromDi,
} from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { provideAuth } from '@interacta-shared/data-access-auth';
import { provideBackendVersionCheck } from '@interacta-shared/data-access-configuration';
import { provideHealthCheck } from '@interacta-shared/util-common';
import { AppRoutingService } from '@modules/app-routing/app-routing.service';
import { outOfService } from '@modules/app-routing/routes';
import {
    CurrentLanguageInterceptor,
    ExponentialBackoffInterceptor,
} from '@modules/core';
import { MockHttpInterceptor } from '@modules/core/helpers/mock.interceptor';
import { HttpNoCacheInterceptorService } from '@modules/core/helpers/no-cache.interceptor';
import { TranslateModule } from '@ngx-translate/core';
import { DateFnsConfigurationService } from 'ngx-date-fns';
import { FallbackImageDirective } from './directives/fallback-image.directive';
import { UserAgentInterceptor } from './helpers/user-agent.interceptor';
const directives = [
    FallbackImageDirective, // keep in core.module
];

@NgModule({
    exports: [...directives],
    declarations: [...directives],
    imports: [TranslateModule, CommonModule],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ExponentialBackoffInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: UserAgentInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpNoCacheInterceptorService,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MockHttpInterceptor,
            multi: true,
        },
        provideHealthCheck(`/${outOfService}`, false),
        provideAuth(AppRoutingService, false),
        provideBackendVersionCheck(false),
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CurrentLanguageInterceptor,
            multi: true,
        },
        provideHttpClient(withInterceptorsFromDi()),
    ],
})
export class CoreModule {
    static forRoot(): ModuleWithProviders<CoreModule> {
        return {
            ngModule: CoreModule,
            providers: [{ provide: DateFnsConfigurationService }],
        };
    }
}
